import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const ServiceWrapper = styled.div`
  column-gap: 2%;
  width: 90vw;
  margin: auto;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  .col-service {
    display: inline-block;
    width: 100%;
  }
  a {
    text-decoration: none;
  }
  .wrapper-service-box {
    margin-bottom: 30px;
    :hover {
      opacity: 0.7;
    }
    .wrapper-title {
      background: linear-gradient(90deg,rgba(65,236,241,1) 0%,rgba(49,104,155,1) 100%);

    }
  }
  h2 {
    color: ${({ theme }) => theme.white};
    margin: 0;
    padding: 0.75rem;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    :first-letter{
      text-transform: uppercase;
    }
  }
  .image {
    width: 100%;
  }
  @media (max-width: 1240px) {
    grid-template-columns: 49% 49%;
    h2 {
      font-size: 22px;
    }
  }
  @media (max-width: 767px) {
    grid-template-columns: 100%;
    h2 {
      font-size: 22px;
    }
  }
`;


export default function Servizi() {
  const data = useStaticQuery(graphql`
    query servicesImages{
      allFile(filter: { sourceInstanceName: { eq: "servicesImages" } }) {
        edges{
          node{
            childImageSharp {
              fluid(quality: 100) {
                originalName
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
`)

  return (
    <Layout pageTitle="Servizi - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
      <Fade left duration={1000}>
        <h1>Servizi</h1>
      </Fade>
      <ServiceWrapper>
        {
          data.allFile.edges.map((image, index) => {
            var url = image.node.childImageSharp.fluid.originalName.replace(".webp", "");
            var titleWithNoImageFormat = url.replace(/-/g, " ");
            return (
              <div className="col-service" key={`service_image_${index}`}>
                <Zoom >
                  <Link to={`/servizio/${url}/`}>
                    <div className="wrapper-service-box">
                      <div className="wrapper-title">
                        <h2>{titleWithNoImageFormat}</h2>
                      </div>
                      <img src={image.node.childImageSharp.fluid.src} alt={url} className="image" />
                    </div>
                  </Link>
                </Zoom>
              </div>
            )
          })
        }
      </ServiceWrapper>
    </Layout>
  )
}